<style scoped>
@media print {
  @page {
    margin: 2mm 5mm 0mm 5mm;
    size: 216mm 140mm;
  }

  thead {
    display: table-header-group;
  }

  .page-break {
    page-break-before: always;
  }

  body {
    font-family: "Courier", monospace !important;
    font-size: 11px !important;
    height: 100% !important;
    color: black !important;
    transform: scale(1);
    transform-origin: top left;
  }

  table {
    width: 100% !important;
    background-color: white !important;
    color: black !important;
    font-size: 11px !important;
  }

  thead {
    display: table-header-group !important;
  }

  th, td {
    font-size: 11px;
    text-align: left;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

table {
  background-color: white !important;
  color: black !important;
}

table tr {
  font-family: "Courier";
  line-height: normal;
  font-size: 11px;
}

table .text-13 tr td {
  font-size: 13px;
}

.text-bold {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
</style>

<template>
  <div>
    <div v-for="(dataPerDoc, dataPerDocIndex) in pageData" :key="`dataPerDoc-${dataPerDocIndex}`">
      <div
        v-for="(dataPerDocPage, dataPerDocPageIndex) in dataPerDoc"
        :key="`dataPerDocPage-${dataPerDocPageIndex}`"
        style="page-break-before: always"
      >
        <table width="100%">
          <tr>
            <td colspan="6" style="text-align: center; padding-bottom: 4px;">
              <div><strong>PROFORMA INVOICE</strong></div>
              <div>
                <strong>{{ dataPerDocPage.company_name }}</strong>
              </div>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">Alamat</td>
            <td style="vertical-align: top">:</td>
            <td colspan="4">{{ dataPerDocPage.warehouse_address }}</td>
          </tr>
          <tr>
            <td>N.P.W.P</td>
            <td>:</td>
            <td>01.604.506.4-007.000</td>
            <td rowspan="10" style="width: 50%;">
              <table>
                <tr>
                  <td>No.DO / No.SO</td>
                  <td>:</td>
                  <td style="white-space: nowrap">
                    {{ dataPerDocPage.delivery_order_code }} / {{ dataPerDocPage.sales_order_code }}
                  </td>
                </tr>
                <tr>
                  <td>Tgl. Transaksi</td>
                  <td>:</td>
                  <td>{{ dataPerDocPage.invoice_proforma_date }}</td>
                </tr>
                <tr>
                  <td><nobr>Syarat Pembayaran</nobr></td>
                  <td>:</td>
                  <td>
                    {{ dataPerDocPage.payment_term_name }} / {{ dataPerDocPage.sales_order_date }}
                  </td>
                </tr>
                <tr>
                  <td>Tgl. Kadaluarsa</td>
                  <td>:</td>
                  <td>
                    {{ dataPerDocPage.invoice_proforma_due_date }}
                  </td>
                </tr>
                <tr>
                  <td>PO No.</td>
                  <td>:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Kode/ Salesman</td>
                  <td>:</td>
                  <td>
                    {{ dataPerDocPage.sales_code }} / {{ dataPerDocPage.sales_name }}
                  </td>
                </tr>
                <tr>
                  <td>Ref. No.</td>
                  <td>:</td>
                  <td>{{ dataPerDocPage.so_reference_code }}</td>
                </tr>
                <tr v-show="dataPerDocPage.is_pbf">
                  <td>PBF. No.</td>
                  <td>:</td>
                  <td>{{ dataPerDocPage.pbf_no }}</td>
                </tr>
                <tr v-show="dataPerDocPage.is_pbf">
                  <td>IPAK. No.</td>
                  <td>:</td>
                  <td>{{ dataPerDocPage.ipak_no }}</td>
                </tr>
                <tr v-show="dataPerDocPage.is_pbf">
                  <td>CDOB. No.</td>
                  <td>:</td>
                  <td>{{ dataPerDocPage.codb_no }}</td>
                </tr>
                <tr>
                  <td><nobr>Print Counter / datetime</nobr></td>
                  <td>:</td>
                  <td>
                    {{ dataPerDocPage.print_counter }} / {{ printDatetime }}
                  </td>
                </tr>
                <tr>
                  <td><nobr>Doc. Type</nobr></td>
                  <td>:</td>
                  <td>
                    {{ dataPerDocPage.document_type }}
                  </td>
                </tr>
                <tr v-show="!dataPerDocPage.is_pbf">
                  <td>&nbsp;</td>
                </tr>
                <tr v-show="!dataPerDocPage.is_pbf">
                  <td>&nbsp;</td>
                </tr>
                <tr v-show="!dataPerDocPage.is_pbf">
                  <td>&nbsp;</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style="width: 125px"><nobr>NITKU</nobr></td>
            <td style="width: 5px">:</td>
            <td>{{ dataPerDocPage.company_nitku }}</td>
          </tr>
          <tr>
            <td style="width: 125px"><nobr>No.Pengukuhan PKP</nobr></td>
            <td style="width: 5px">:</td>
            <td>01.604.506.4-007.000</td>
          </tr>
          <tr>
            <td>Tgl. Pengukuhan</td>
            <td>:</td>
            <td>09-04-2007</td>
          </tr>
          <tr>
            <td><nobr>Vendor Transportir</nobr></td>
            <td>:</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Vehicle No.</td>
            <td>:</td>
            <td>{{ dataPerDocPage.vehicle_number }}</td>
          </tr>
          <tr>
            <td>Driver Name</td>
            <td>:</td>
            <td>{{ dataPerDocPage.driver_name }}</td>
          </tr>
          <tr>
            <td style="padding-bottom: 7px"></td>
          </tr>
          <tr>
            <td>Kepada Yth</td>
            <td>:</td>
            <td>
              {{ dataPerDocPage.customer_code }}
              {{ dataPerDocPage.customer_name }}
            </td>
          </tr>
          <tr>
            <td colspan="3">
              {{ dataPerDocPage.customer_address }}
            </td>
          </tr>
          <tr>
            <td>NPWP</td>
            <td>:</td>
            <td>{{ dataPerDocPage.customer_npwp }}</td>
          </tr>
          <tr>
            <td>NITKU</td>
            <td>:</td>
            <td>{{ dataPerDocPage.customer_nitku ? dataPerDocPage.customer_nitku : '-' }}</td>
          </tr>
          <tr>
            <td><nobr>Sold-To Code / Name</nobr></td>
            <td>:</td>
            <td>
              {{ dataPerDocPage.bill_to_code }} / {{ dataPerDocPage.bill_to_name }}
            </td>
          </tr>
        </table>
        <div style="height: 5px"></div>
        <table width="100%">
          <thead>
            <tr style="border-top: 2px dashed; border-bottom: 2px dashed" v-if="dataPerDocPage.lines.length != 0">
              <td class="text-left text-bold" style="width: 20px">NO</td>
              <td class="text-left text-bold" style="width: 50px; padding-left: 15px">
                Item
              </td>
              <td class="text-left text-bold" style="padding-left: 15px">
                Item Name
              </td>
              <td v-show="dataPerDocPage.is_pbf" class="text-left text-bold" style="padding-left: 15px">
                Batch No
              </td>
              <td v-show="dataPerDocPage.is_pbf" class="text-left text-bold" style="padding-left: 15px">
                Exp. Date
              </td>
              <td class="text-left text-bold" style="width: 30px; padding-left: 10px">Qty</td>
              <td class="text-right text-bold" style="width: 40px; padding-left: 10px">UOM</td>
              <td class="text-right text-bold" style="width: 75px; padding-left: 5px">Price</td>
            </tr>
          </thead>
          <tbody>
            <template v-for="(line, indexLine) in dataPerDocPage.lines">
              <tr :key="`item-line-${indexLine}`">
                <td class="text-left text-13" style="font-size: 13px; padding-top: 2px;">
                  {{ line.row_number }}
                </td>
                <td
                  class="text-left text-13"
                  style="font-size: 13px; padding-left: 15px"
                >
                  {{ line.sku_code }}
                </td>
                <td
                  class="text-left text-13"
                  style="font-size: 13px; padding-left: 15px"
                >
                  <!-- {{ truncateText(line.item_name, 25) }} -->
                  {{ line.item_name }}
                </td>
                <td
                  class="text-left text-13"
                  style="font-size: 13px; padding-left: 15px"
                  v-show="dataPerDocPage.is_pbf"
                >
                  {{ line.batch_external }}
                </td>
                <td
                  class="text-left text-13"
                  style="font-size: 13px; padding-left: 15px"
                  v-show="dataPerDocPage.is_pbf"
                >
                  {{ line.expired_date }}
                </td>
                <td class="text-left text-13" style="font-size: 13px; padding-left: 10px">
                  {{ line.qty }}
                </td>
                <td class="text-right text-13" style="font-size: 13px; padding-left: 10px">
                  {{ line.item_unit }}
                </td>
                <td class="text-right text-13" style="font-size: 13px; padding-left: 5px">
                  {{ formatNumber(line.total) }}
                </td>
              </tr>
              <tr
                v-if="(indexLine == dataPerDocPage.lines.length - 1 && dataPerDocPageIndex == dataPerDoc.length - 1) || (dataPerDoc[dataPerDoc.length-1].lines.length == 0 && indexLine == dataPerDocPage.lines.length - 1 && dataPerDocPage.lines.length <= linePerPage && dataPerDocPageIndex == dataPerDoc.length - 2)"
                :key="`item-total-final-${indexLine}`"
                style="border-top: 2px dashed; margin-top: 3px"
              >
                <td v-show="dataPerDocPage.is_pbf" colspan="7" class="text-right text-bold" style="font-size: 13px;">Total Price</td>
                <td v-show="!dataPerDocPage.is_pbf" colspan="5" class="text-right text-bold" style="font-size: 13px;">Total Price</td>
                <td class="text-right text-bold" style="font-size: 13px; padding-left: 10px;">
                  {{ formatNumber(dataPerDocPage.total_price) }}
                </td>
              </tr>

              <!-- jika terdapat lebih dari 1 halaman -->
              <tr
                v-if="(dataPerDoc[dataPerDoc.length-1].lines.length > 0 && indexLine == dataPerDocPage.lines.length - 1 && dataPerDocPageIndex < dataPerDoc.length - 1 && dataPerDocPage.lines.length <= linePerPage) || (dataPerDoc[dataPerDoc.length-1].lines.length == 0 && indexLine == dataPerDocPage.lines.length - 1 && dataPerDocPageIndex < dataPerDoc.length - 2 && dataPerDocPage.lines.length == linePerPage)"
                :key="`item-dashed-line-${indexLine}`"
                style="border-top: 2px dashed"
              >
                <td>&nbsp;</td>
              </tr>

              <!-- jika hanya menggunakan 1 halaman saja -->
              <tr
              v-if="(indexLine == dataPerDocPage.lines.length - 1 && dataPerDocPageIndex == dataPerDoc.length - 1) || (dataPerDoc[dataPerDoc.length-1].lines.length == 0 && indexLine == dataPerDocPage.lines.length - 1 && dataPerDocPage.lines.length <= linePerPage && dataPerDocPageIndex == dataPerDoc.length - 2)"
                style="border-top: 2px dashed"
                :key="`message-final-${indexLine}`"
              >
                <td colspan="6">Message :</td>
              </tr>

              <!-- jika hanya menggunakan 1 halaman saja -->
              <tr
                :key="`item-total2-${indexLine}`"
                v-if="indexLine == dataPerDocPage.lines.length - 1 && dataPerDocPageIndex == dataPerDoc.length - 1"
              >
                <td colspan="12">
                  <table style="width: 100%">
                    <div v-if="4 - dataPerDocPage.lines.length > 0">
                      <template v-for="index in 4 - dataPerDocPage.lines.length">
                        <tr :key="`space-${index}`">
                          <td style="font-size: 13px">&nbsp;</td>
                        </tr>
                      </template>
                    </div>

                    <tr>
                      <td class="text-center text-bold">
                        {{ dataPerDocPage.invoice_proforma_date }},
                        <br/>Yang Menyerahkan
                      </td>
                      <td v-show="dataPerDocPage.is_pbf" class="text-center text-bold">Apoteker</td>
                      <td class="text-center text-bold">Ekspedisi</td>
                      <td class="text-center text-bold" style="white-space: nowrap">
                        Tanggal Diterima : .&nbsp;.&nbsp;.&nbsp;.&nbsp;.
                        <br />
                        Yang Menerima
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td class="text-center text-bold">
                        (...............)
                        <br />
                        Nama Jelas
                      </td>
                      <td v-show="dataPerDocPage.is_pbf" class="text-center text-bold">
                        (...............)
                        <br />
                        Nama Jelas
                      </td>
                      <td class="text-center text-bold">
                        (...............)
                        <br />
                        Nama Jelas
                      </td>
                      <td class="text-center text-bold">
                        (...............)
                        <br />
                        Nama Jelas dan Cap
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <div>Pemberitahuan Penting:</div>
                        <div>
                          1. Surat Jalan ini bukan merupakan bukti pembayaran
                          yang sah.
                        </div>
                        <div>
                          2. Mintalah invoice asli dan faktur pajak setiap kali
                          akan melakukan pembayaran.
                        </div>
                        <div>
                          3. Pembayaran tanpa disertai bukti yang sah merupakan
                          tanggung jawab PELANGGAN sepenuhnya.
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <table
          style="width: 100%"
          v-if="dataPerDocPage.lines.length < limitLastLine && dataPerDoc.length > 1 && dataPerDocPage.lines.length == 0"
        >
          <template v-for="index in 9 - dataPerDocPage.lines.length">
            <tr :key="`space-${index}`">
              <td>&nbsp;</td>
            </tr>
          </template>
          <tr>
            <td class="text-center text-bold">
              {{ dataPerDocPage.invoice_proforma_date }}, <br/>Yang Menyerahkan
            </td>
            <td v-show="dataPerDocPage.is_pbf" class="text-center text-bold">Apoteker</td>
            <td class="text-center text-bold">Ekspedisi</td>
            <td class="text-center text-bold" style="white-space: nowrap">
              Tanggal Diterima : .&nbsp;.&nbsp;.&nbsp;.&nbsp;.
              <br/>
              Yang Menerima
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td class="text-center text-bold">
              (...............)
              <br />
              Nama Jelas
            </td>
            <td v-show="dataPerDocPage.is_pbf" class="text-center text-bold">
              (...............)
              <br />
              Nama Jelas
            </td>
            <td class="text-center text-bold">
              (...............)
              <br />
              Nama Jelas
            </td>
            <td class="text-center text-bold">
              (...............)
              <br />
              Nama Jelas dan Cap
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div>Pemberitahuan Penting:</div>
              <div>
                1. Surat Jalan ini bukan merupakan bukti pembayaran yang sah.
              </div>
              <div>
                2. Mintalah invoice asli dan faktur pajak setiap kali akan
                melakukan pembayaran.
              </div>
              <div>
                3. Pembayaran tanpa disertai bukti yang sah merupakan tanggung
                jawab PELANGGAN sepenuhnya.
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";

export default {
  name: "PrintPdf",
  data() {
    return {
      doIds: [],
      datas: [],
      is_pod: false,
      timeNow: "",
      printDatetime: moment().format("DD.MM.YYYY HH:mm:ss"),
      counter: 0,
      pageData: [],
      linePerPage: 11,
      limitLastLine: 4,
    };
  },
  mounted() {
    this.doIds = window.opener.doIds;
    this.is_pod = window.opener.is_pod;
    if (this.doIds.length) {
      this.print();
    }
  },
  methods: {
    async print() {
      const tempTitle = document.title;
      await this.getData();
      await window.print();
      document.title = tempTitle;
    },
    async getData() {
      this.$vs.loading();
      let resp = await this.$http.get(`/api/wms/v1/simple-outbound-planner/surat-jalan2`,
        {
          params: {
            delivery_order_ids: this.doIds,
            is_print: 1,
            is_pod: this.is_pod,
          },
        }
      );
      if (resp.code == 200) {
        this.datas = resp.data;
        document.title = this.datas.reduce(
          (prev, curr) => `${prev}-${curr.delivery_order_code}`,
          "Surat_Jalan"
        );
        var pageData = [];
        var limitLastLine = this.limitLastLine;

        // console.log("get data => ", resp.data)
        this.datas.forEach((data, indexDoc) => {
          var dataPerDocument = [];
          var dataPerDocumentLines = [];
          var linePerPageCounter = 0;
          var rowNumber = 1;

          data.lines.forEach((line, indexLine) => {
            linePerPageCounter++;

            if (linePerPageCounter == 1) {
              dataPerDocumentLines = [];
            }
            line.row_number = rowNumber;
            rowNumber++;
            dataPerDocumentLines.push(line);

            if (linePerPageCounter == this.linePerPage || indexLine == data.lines.length - 1) {
              let dataPerPage = { ...data };
              dataPerPage.lines = [];
              dataPerPage.lines = dataPerDocumentLines;
              dataPerDocument.push(dataPerPage);
              linePerPageCounter = 0;
            }
          });

          // check last page
          if (dataPerDocument.length > 0) {
            if (dataPerDocument[dataPerDocument.length - 1].lines.length > limitLastLine) {
              let dataPerPage = {
                ...dataPerDocument[dataPerDocument.length - 1],
              };
              dataPerPage.lines = [];
              dataPerDocument.push(dataPerPage);
            }
          }

          pageData.push(dataPerDocument);
        });

        /* pageData.forEach((data) => {
          data.forEach((d) => {
            console.log("line length => ", d.lines.length);
          });
        }); */

        // console.log("pageData => ", pageData);
        this.pageData = pageData;
        const now = new Date();
        this.timeNow = this.formatDate(now) + " " + this.formatTime(now);
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        const halfLength = Math.floor((maxLength - 3) / 2)
        const start = text.substring(0, halfLength)
        const end = text.substring(text.length - halfLength)
        return `${start}...${end}`
      }
      return text;
    },
  },
  watch: {
    counter: {
      handler: function (val) {
        console.log("counter => ", val);
      },
      deep: true,
    },
  },
  computed: {
    formatDate: () => {
      return (val) => {
        const momentDate = moment.utc(val);
        return momentDate.format("YYYY-MM-DD");
      };
    },
    formatTime: () => {
      return (val) => {
        const momentDate = moment.utc(val);
        return momentDate.format("hh:mm:ss");
      };
    },
    formatNumber: () => {
      return (val) => {
        if (val == null) {
          return 0;
        }
        return Intl.NumberFormat("id", { minimumFractionDigits: 0 }).format(
          val
        );
      };
    },
  },
};
</script>